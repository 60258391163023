<template>
  <div class="main-content mt-2">
    <div class="header">
      <b-alert variant="info" :show="message != null">{{message}}</b-alert>
      <b-alert variant="danger" :show="error != null">{{error}}</b-alert>
    </div>
    <div class="container-fluid">
      <div class="p-2 border-bottom border-info">
        <h4 class="text-info font-italic">
          <strong>管理者リスト</strong>
          <span class="ml-2"><b-btn variant="info" @click="reloadItems"><i class="fas fa-sync"/> 再読込</b-btn></span>
          <span class="ml-2"><b-btn variant="primary" @click="showRegisterModal"><i class="fas fa-plus"/> 新規登録</b-btn></span>
        </h4>
      </div>
      <div>
        <b-table
          :items="users"
          :fields="table.fields"
          :filter="table.filter"
          :filter-included-fields="table.filterOn"
          :sort-by.sync="table.sortBy"
          :sort-desc.sync="table.sortDesc"
          :sort-direction="table.sortDirection"
          striped
          bordered
          hover
          stacked="md"
          show-empty
          small
          responsive
          class="step-table"
        >
          <template #cell(userNo)="data">
            <b-btn variant="link" size="sm" class="text-nowrap" @click="showDetailModal(data.item)"><b>{{data.item.Username}}</b></b-btn>
          </template>
          <template #cell(Enabled)="data">
            {{data.item.Enabled ? '有効' : '無効'}}
          </template>
          <template #cell(Email)="data">
            {{ formatEmail(data.item) }}
          </template>
          <template #cell(sub)="data">
            {{data.item.Attributes[0].Value}}
          </template>
          <template #cell(UserStatus)="data">
            <span v-if="data.item.UserStatus === 'UNCONFIRMED'">
              <b-btn variant="link" size="sm" @click="showModal(data.item)">{{data.item.UserStatus}}</b-btn>
            </span>
            <span v-else>
              {{data.item.UserStatus}}
            </span>
          </template>
          <template #cell(UserCreateDate)="data">
            {{ formatUserCreateDate(data.value) }}
          </template>
          <template #cell(UserLastModifiedDate)="data">
            {{ formatUserLastModifiedDate(data.value) }}
          </template>
          <template #cell(btns)="data">
            <b-btn variant="warning" size="sm" @click="showPasswordChangeModal(data.item)"><b-icon icon="pencil"></b-icon></b-btn>
            <b-btn variant="danger" size="sm" class="ml-1" @click="showUserRemoveModal(data.item)"><b-icon icon="trash"></b-icon></b-btn>
          </template>
        </b-table>
      </div>
      <div v-if="paginationToken"><b-btn @click="loadMore">もっと読み込む</b-btn></div>
    </div>
    <b-modal ref="userDetailModal" centered title="詳細"
      header-bg-variant="info"
      header-text-variant="light"
      hide-footer>
      <div>
        <p class="my-4">
          <b-list-group v-for="(key, idx) in Object.keys(currentUser)" :key="idx">
            <b-list-group-item variant="secondary">
              <span>{{ key }}:</span>
              <strong class="pl-2">{{ currentUser[key] }}</strong>
            </b-list-group-item>
          </b-list-group>
        </p>
      </div>
    </b-modal>
    <b-modal ref="passwordChangeModal" centered title="パスワード変更"
      header-bg-variant="info"
      header-text-variant="light"
      @ok="onPasswordChanged"
      >
      <b-alert variant="danger" :show="registerError != null">{{registerError}}</b-alert>
      <form @submit.stop.prevent="onPasswordChanged">
        <b-form-group label="新しいパスワード:">
          <b-form-input
                      id="password"
                      type="password"
                      size="13"
                      v-model="form.password"
                      :state="validators.password"
                      required
                      placeholder="8文字以上。英数字大文字を一つ以上含むを推奨">
          </b-form-input>
          <b-form-invalid-feedback id="passwordFeedback">
            8文字以上(英数字大文字を一つ以上含む)を入力してください
          </b-form-invalid-feedback>
        </b-form-group>
      </form>
    </b-modal>
    <b-modal ref="userRemoveModal" centered title="ユーザの削除"
      header-bg-variant="info"
      header-text-variant="light"
      hide-footer>
      <div>
        <p class="my-4">
          <span class="text-danger"><b>このユーザ{{currentUser.Username}}を削除しますか？ この操作は取り消せません</b></span>
        </p>
        <b-btn class="mt-3" variant="outline-danger" block @click="onRemoveUser">削除</b-btn>
      </div>
    </b-modal>
    <b-modal id="registerModal" ref="registerModal" title="新規登録"
      header-bg-variant="info"
      header-text-variant="light"
      @ok="onRegister"
      >
      <b-alert variant="danger" :show="registerError != null">{{registerError}}</b-alert>
      <form @submit.stop.prevent="onRegister">
        <b-form-group label="ログインID:">
          <b-form-input
                      id="username"
                      type="text"
                      size="10"
                      v-model="form.username"
                      :state="validators.username"
                      required
                      placeholder="必須">
          </b-form-input>
          <b-form-invalid-feedback id="usernameFeedback">
            入力してください
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="email">
          <b-form-input
                      id="email"
                      type="text"
                      size="13"
                      v-model="form.email"
                      :state="validators.email"
                      required
                      placeholder="必須">
          </b-form-input>
          <b-form-invalid-feedback id="emailFeedback">
            入力してください
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="パスワード:">
          <b-form-input
                      id="password"
                      type="password"
                      size="13"
                      v-model="form.password"
                      :state="validators.password"
                      required
                      placeholder="8文字以上。英数字大文字を一つ以上含むを推奨">
          </b-form-input>
          <b-form-invalid-feedback id="passwordFeedback">
            8文字以上(英数字大文字を一つ以上含む)を入力してください
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="名前:">
          <b-form-input
                      id="nickname"
                      type="text"
                      size="10"
                      v-model="form.nickname"
                      :state="validators.nickname"
                      required
                      placeholder="必須">
          </b-form-input>
          <b-form-invalid-feedback id="nicknameFeedback">
            入力してください
          </b-form-invalid-feedback>
        </b-form-group>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { Logger } from 'aws-amplify'
import moment from 'moment-timezone'

const logger = new Logger('CognitoAdmins')

export default {
  name: 'CognitoAdmins',
  data () {
    return {
      loading: false,
      message: null,
      error: null,
      users: [],
      filteredUsers: [],
      table: {
        fields: [
          // { key: 'consentId', label: 'ID', sortable: true, sortDirection: 'desc', visible: false },
          { key: 'Username', label: 'ログインID', sortable: true, sortDirection: 'desc', variant: 'primary' },
          { key: 'Email', label: 'Email' },
          { key: 'UserStatus', label: 'ステータス' },
          { key: 'Enabled', label: '有効' },
          { key: 'sub', label: 'sub' },
          { key: 'UserCreateDate', label: '登録日時' },
          { key: 'UserLastModifiedDate', label: '更新日時' },
          { key: 'btns', label: '' }
        ],
        sortBy: 'Username',
        sortDesc: true,
        sortDirection: 'asc',
        filter: null,
        filterOn: []
      },
      paginationToken: null,
      currentUser: {},
      registerError: null,
      validators: [],
      form: {
        username: null,
        email: null,
        password: null
      }
    }
  },
  mounted: function () {
    this.loadItems()
  },
  methods: {
    open (id) {
      logger.debug('open', id)
    },
    formatDate (time) {
      return moment(time).format('MM/DD HH:mm')
    },
    formatEmail (user) {
      let attr = user.Attributes.find(attr => attr.Name === 'email')
      return attr && attr['Value']
    },
    formatNickname (user) {
      let attr = user.Attributes.find(attr => attr.Name === 'nickname')
      return attr && attr['Value']
    },
    formatUserCreateDate (row) {
      return (!row.UserCreateDate) ? '' : moment(row.UserCreateDate, 'x').format('YYYY/MM/DD HH:mm')
    },
    formatUserLastModifiedDate (row) {
      return (!row.UserLastModifiedDate) ? '' : moment(row.UserLastModifiedDate, 'x').format('YYYY/MM/DD HH:mm')
    },
    async loadMore () {
      await this.loadItems()
    },
    async loadItems () {
      this.message = null
      this.error = null
      this.users = []
      try {
        const data = await this.$store.dispatch('fetchCognitoAdmins', { paginationToken: this.paginationToken })
        logger.debug('found users:', data)
        this.paginationToken = data.PaginationToken
        data.Users.forEach(user => {
          this.users.push(user)
        })
      } catch (error) {
        logger.error(error)
        this.error = error.message || error
      }
    },
    reloadItems () {
      this.users = []
      this.paginationToken = null
      this.loadItems()
    },
    onRemoveUser () {
      this.message = null
      this.error = null
      let username = this.currentUser.Username
      this.$store.dispatch('deleteCognitoAdmin', { username }).then((data) => {
        logger.debug(data)
        this.message = '削除しました'
        this.$refs.userRemoveModal.hide()
        this.loadItems()
      }).catch(err => {
        logger.error(err)
        this.error = err.message || err
      })
    },
    showDetailModal (user) {
      this.currentUser = user
      this.$refs.userDetailModal.show()
    },
    showModal (user) {
      this.currentUser = user
      this.$refs.userRemoveModal.show()
    },
    showUserRemoveModal (user) {
      this.currentUser = user
      this.$refs.userRemoveModal.show()
    },
    validate () {
      this.validators.username = this.form.username && this.form.username.length >= 1
      this.validators.email = this.form.email && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(this.form.email)
      this.validators.password = this.form.password && this.form.password.length >= 8
      return ['username', 'email', 'password'].every(p => this.validators[p])
    },
    async onRegister () {
      this.error = null
      this.message = null
      logger.debug(this.form)
      if (!this.validate()) {
        logger.debug(this.validators)
        return
      }
      this.form.email = this.form.email.toLowerCase()
      let newData = Object.assign({}, this.$data.form)
      this.loading = true
      try {
        await this.$store.dispatch('createCognitoAdmin', { newUser: newData })
        await this.$store.dispatch('updatePasswordCognitoAdmin', {
          username: newData.username, newPassword: newData.password
        })

        this.loading = false
        this.message = '登録しました'
        this.form = {}
        this.validators = {}
        this.$refs.registerModal.hide()
        this.reloadItems()
      } catch (err) {
        this.loading = false
        logger.error(err)
        this.registerError = err.message || err
      }
    },
    showRegisterModal () {
      this.form = {}
      this.$refs.registerModal.show()
    },
    showPasswordChangeModal (user) {
      this.currentUser = user
      this.$refs.passwordChangeModal.show()
    },
    onPasswordChanged () {
      this.error = null
      this.message = null
      logger.debug(this.form)
      if (!this.form.password || this.form.password.length < 8) {
        return false
      }
      this.loading = true
      return this.$store.dispatch('updatePasswordCognitoAdmin', {
        username: this.currentUser.Username, newPassword: this.form.password
      }).then(() => {
        this.loading = false
        this.message = '更新しました'
        this.form = {}
        this.validators = {}
        this.$refs.passwordChangeModal.hide()
      }).catch(err => {
        this.loading = false
        logger.error(err)
        this.registerError = err.message || err
      })
    }
  }
}
</script>

<style scoped>
.step-table, .users-table {
  font-size: 0.75rem;
}
</style>
